<template>
  <section>
    <b-table-simple 
      v-if="kits?.length && !isLoading"
      id="guides-table"
      responsive 
      borderless 
      class="table-eyecare mt-2 w-100"
    >
      <thead>
        <tr>
          <th>Cód. Pré kit</th>
          <th>Cód. de barras</th>
          <th>Nome do kit</th>
          <th>Origem</th>
          <th class="text-align-center">Qtde de produtos</th>
          <th>Data de validade</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="(kit, index) in kits" :key="index">
        <tr>
          <td>
            <strong>{{ kit?.template?.code }}</strong>
          </td>
          <td>
            <strong>{{ kit?.code }}</strong>
          </td>
          <td>
            {{ kit?.template?.name }}
          </td>
          <td>
            {{ kit?.origin?.name }}
          </td>
          <td class="text-align-center">
            {{ kit?.kit_products?.length }}
            {{ kit?.kit_products?.length > 1 ? 'produtos' : 'produto' }}
          </td>
          <td class="text-align-center">
            {{ getKitExpirationDate(kit) }}
          </td>
          <td class="no-print text-align-center">
            <b-button 
              v-b-toggle="`collapse-${kit?.id}-${index}`" 
              class="toggle-btn" 
              @click="rotateIcon"
            >
              <ChevronDown class="icon" />
            </b-button>
            <div class="options">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button
                  variant="link"
                  @click="edit(kit)"
                >
                  Editar
                </b-button>
                <b-button
                  variant="link remove mb-0"
                  @click="remove(kit?.id)"
                >
                  Excluir
                </b-button>
              </div>
            </div>
          </td>
        </tr>
        <tr class="collapsable-row">
          <td colspan="12" class="p-0">
            <b-collapse :id="`collapse-${kit?.id}-${index}`" class="collapse">
              <b-table-simple 
                class="subtable collapse-table" 
                responsive borderless fixed 
              >
                <thead>
                  <th>Código</th>
                  <th>Produto</th>
                  <th>Lote</th>
                  <th>Validade</th>
                  <th>Quantidade</th>
                  <th>Custo</th>
                  <th>Valor de venda</th>
                </thead>
                <tbody>
                  <tr v-for="(product, i) in kit?.kit_products" :key="i">
                    <td>
                      <strong>{{ product?.product?.barcode }}</strong>
                    </td>
                    <td>{{ product?.product?.name }}</td>
                    <td>{{ product?.batch?.batch }}</td>
                    <td>{{ parseDate(product?.expiration_date) }}</td>
                    <td>{{ product?.quantity }}</td>
                    <td>{{ parseNumberToMoney(product?.cost) }}</td>
                    <td>{{ parseNumberToMoney(product?.selling_cost) }}</td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-collapse>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <div v-if="isLoading || !kits?.length" class="text-align-center">
      <div v-if="isLoading" class="mt-5 mb-5">
        <b-skeleton v-for="n in 3" :key="n" height="50px" class="mb-2" />
      </div>
      <noFilterResults v-else-if="!kits?.length" message="Os filtros aplicados não geraram nenhum resultado." />
    </div>
  </section>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { parseDate } from '@/utils/dateHelper'

export default {
  name: 'kits-table',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    noFilterResults: () => import('@/components/General/noFilterResults.vue'),
  },
  props: {
    kits: Array,
    isLoading: Boolean,
  },
  data () {
    return {
    }
  },
  methods: {
    parseDate,
    parseNumberToMoney,
    rotateIcon($event) {
      const icon = $event.target.closest('button').querySelector('.icon')
      icon.classList.toggle('rotate')
    },
    getKitExpirationDate(kit) {
      let expirationDate = null
      kit.kit_products.map(product => {
        if (!expirationDate || product?.expiration_date < expirationDate) {
          expirationDate = product?.expiration_date
        }
      })

      return this.parseDate(expirationDate)
    },
    edit (kitAux) {  
      const kit = JSON.parse(JSON.stringify(kitAux)) 
      const kitProducts = {}
      kit.kit_products.map(el => {
        if (!kitProducts[el?.product_id]) {
          this.$set(kitProducts, el?.product_id, [])
        }

        kitProducts[el?.product_id].push({
          batch_id: el?.batch_id,
          batch: el?.batch,
          expiration_date: this.moment(el?.expiration_date).toDate(),
          quantity: el?.quantity,
          cost: el?.cost,
          selling_cost: el?.selling_cost,
          kit_product_id: el?.id,
          product: el?.product,
        })
      })
      this.$set(kit, 'products', kit?.kit_products)
      this.$set(kit, 'kit_products', kitProducts) 
      this.$emit('edit', kit)
    },
    remove (kitId) {
      this.$emit('delete', kitId)
    },
    isBelowMinimum(product) {
      return product.product_balance && product.product_balance.balance < product.minimum_quantity;
    },
    isEqualMinimum(product) {
      return product.product_balance && product.product_balance.balance === product.minimum_quantity;
    }
  }
}
</script>

<style lang="scss" scoped>

@media(max-width: 1440px) {
  .table-eyecare th,td{
    font-size: 0.8rem !important;
  }
}

thead {
  background: var(--neutral-100) !important;
}
th { padding: 15px 0 15px 10px !important; }
.subtable {
  border-radius: 8px;
  border: 1px dashed var(--neutral-300) !important;

  tr:not(:last-child) {
    border-bottom: 1px dashed var(--neutral-300) !important;
  }
}

tr {
  border: none !important;
  padding: 0px 25px !important;
}

tr:nth-child(even) {
  background: var(--neutral-000) !important;
}

td {
  vertical-align: middle !important;
  .blue-text {
    color: var(--blue-500);
  }

  .checkbox-td {
    display: flex;
    justify-content: center;
    .checkbox {
      margin-bottom: 20px !important;
      vertical-align: middle !important;
      display: flex;
    }
  }
}

.neutral-bg {
  background: var(--neutral-100) !important;
}

.collapsable-row {
  td {
    border: none !important;
    padding-left: 20px !important;
  }
}
.toggle-btn {
  background: var(--blue-100);
  border: none;
  padding: 5px !important;

  @media(min-width: 1440px) {
    margin-right: 50px;
  }

  @media(max-width: 1440px) {
    margin-right: 20px;
  }

  .icon {
    width: 25px;
    transition: all 500ms;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

.options {
  cursor: pointer;
  width: 24px;
  height: 28px;
  position: relative;
  display: inline-block;

  .more-icon {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    transition: all 0.5s;
    cursor: pointer;
  }

  .menu {
    width: 141px;
    position: absolute;
    top: 28px;
    right: 0;
    background: var(--neutral-000);
    border: 1px solid var(--neutral-200);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
      0px 4px 10px -8px rgba(12, 29, 89, 0.2);
    border-radius: 8px;
    padding: 0;
    opacity: 0;
    transition: all 0.5s;
    flex-direction: column;
    align-items: start;
    display: none;

    .mtb-12 {
      margin: 12px 0;
    }

    .btn {
      font-weight: 600;
      width: 100%;

      &.remove {
        color: var(--states-error);
      }

      &.btn-link {
        text-align: left;
        padding: 12px 18px !important;
      }
    }
  }

  &:hover {
    .more-icon {
      transform: rotate(90deg);
    }

    .menu {
      opacity: 1;
      display: flex;
      z-index: 10;
    }
  }
}
</style>